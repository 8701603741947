import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { PrevButton, NextButton } from 'components/bootstrap/button-wrapper';
import GoogleAutocompleteWrapper from 'components/vendors/google-autocomplete-wrapper';
import GoogleMapWrapper from 'components/vendors/google-map-wrapper';

import Empty from 'components/misc/empty';
import { SectionPreBlock, SectionBlock, SectionTitle, SectionBody, SectionFooter } from 'components/blocks/section-blocks';
import { isEmpty } from 'utils/helpers';
import { setProp, setRelation } from 'utils/store-helpers';

const BookingFlowLocation = (props) => {
  const { booking, prevAction, nextAction, store, toast, session } = props;
  const [providers, setProviders] = useState(true);
  const [taskRunning, setTaskRunning] = useState(false);


  // Methods
  const checkProviders = async (location) => {
    try {
      setTaskRunning(true);
      store.adapterFor('provider').set('action', 'proximity');
      let providers = await store.query('provider', {
        status: 'active',
        latitude: location.latitude,
        longitude: location.longitude,
      });
      if (providers.length > 0) {
        toast.showSuccess('Providers available!');
        nextAction();
      } else {
        toast.showSuccess('Providers unavailable');
        setProviders(false);
      }
    } catch (e) {
      toast.showError(e);
    } finally {
      store.adapterFor('provider').set('action', null);
      setTaskRunning(false);
    }
  }

  const tryAgain = (booking) => {
    setProp(booking, 'location', {});
    setProviders(!providers);
  }


  // Render
  return (
    <Fragment>
      <SectionPreBlock>
        <PrevButton title='Exit' onClick={prevAction} />
      </SectionPreBlock>

      <SectionBlock>
        <div className={'section-title flex'}
          style={{
            flexDirection: 'row',
          }}>
          <h5 className='title'>Where should we meet you?</h5>
          <h6 style={{
            marginLeft: 10
          }}>{"We service areas in Colorado, Arizona and Texas"}</h6>
        </div>
        <SectionBody className='md'>
          <Row className=''>
            {providers ? (
              <Col>
                <FormGroup>
                  <GoogleAutocompleteWrapper
                    type='wizard'
                    location={booking.location}
                    setLocation={location => setRelation(booking, 'location', location)}
                  />
                </FormGroup>

                <GoogleMapWrapper model={booking.location} className='lg mb-15 lighter-gray-bg' />
              </Col>
            ) : (
              <Empty
                type='index'
                icon='map-marker-alt-slash'
                title='No Results'
                msg="Sorry, we don't have any providers in your area just yet.">
                <NextButton title='Try Again' onClick={() => tryAgain(booking)} />
              </Empty>
            )}
          </Row>
        </SectionBody>
        <SectionFooter className='flex-between'>
          <NextButton
            title='Next'
            taskRunning={taskRunning}
            disabled={isEmpty(booking.location)}
            onClick={() => checkProviders(booking.location)}
            className='btn-lg btn-block'
          />
        </SectionFooter>
      </SectionBlock>
    </Fragment>
  )
}

export default BookingFlowLocation;
